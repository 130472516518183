/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import { useReducer } from 'react';
import axios, { CancelToken } from 'axios';
import { v4 as uudiv4 } from 'uuid';
import NoItensFound from '../NoItensFound';
import { WarehouseCostCards } from './styles';
import WareHouseFinanceiro from '../../dtos/IWareHouseFinanceiroDTO';
import image_not_available from '../../assets/image_not_available.png';

interface WarehouseCostListProps {
  query: string;
}

type StateType = {
  isLoading: boolean;
  hasError: boolean;
  hits?: WareHouseFinanceiro[];
  resultState: 'initialState' | 'success' | 'noItensFound';
};

type ActionType =
  | { type: 'FETCH_START' }
  | { type: 'FETCH_SUCCESS'; payload: WareHouseFinanceiro[] }
  | { type: 'FETCH_FAILURE' };

function fetchReduce(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        isLoading: true,
        hasError: false,
        resultState: 'initialState',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hits: action.payload,
        resultState: 'success',
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hits: [],
        resultState: 'noItensFound',
      };
    default:
      throw new Error();
  }
}

const WarehouseListCost: React.FC<WarehouseCostListProps> = React.memo(
  ({ query }: WarehouseCostListProps) => {
    const [{ hits, isLoading, resultState }, dispatch] = useReducer(
      fetchReduce,
      {
        hits: [],
        isLoading: true,
        hasError: false,
        resultState: 'initialState',
      },
    );

    async function fetchHits(
      query: string,
      dispatch: React.Dispatch<ActionType>,
      cancelToken: CancelToken,
    ): Promise<void> {
      dispatch({ type: 'FETCH_START' });
      try {
        const result = await axios(
          `${process.env.REACT_APP_API_URL}/finance/itemCost?description=${query}`,
          {
            cancelToken,
          },
        );
        const arrayLength = result.data.length;

        if (arrayLength === 0 && query !== 'nonExistentItem') {
          dispatch({ type: 'FETCH_FAILURE' });
          return;
        }
        if (query === ' ') {
          return;
        }
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        axios.isCancel(err) || dispatch({ type: 'FETCH_FAILURE' });
      }
    }

    const debouncedFetchHits = useRef(
      _.debounce(
        (query: string, cancelToken: CancelToken) =>
          fetchHits(query, dispatch, cancelToken),
        500,
      ),
    ).current;

    useEffect(() => {
      const { cancel, token } = axios.CancelToken.source();
      debouncedFetchHits(query, token);

      return () => cancel('No longer latest query');
    }, [debouncedFetchHits, query]);

    function createNumberLote(data: string): string {
      switch (data) {
        case '2018-10-27': {
          return '1º';
        }
        case '2019-04-08': {
          return '2º';
        }
        case '2019-06-30': {
          return '3º';
        }
        case '2019-11-04' || 'HE020936' || 'HE020930' || 'HE020941': {
          return '4º';
        }
        case '2020-02-03': {
          return '5º';
        }
        case '2020-06-25': {
          return '6º';
        }
        case '2020-09-02': {
          return '7º';
        }
        case '2020-10-23': {
          return '8º';
        }
        case '2021-02-03': {
          return '9º';
        }
        case '2021-05-11': {
          return '10º';
        }
        case '2021-05-24': {
          return '11º';
        }
        case '2021-06-24': {
          return '12º';
        }
        case '2021-09-30': {
          return '13º';
        }
        case '2022-02-04': {
          return '14º';
        }
        case '2022-05-26': {
          return '15º';
        }
        case '2022-06-22': {
          return '16º';
        }
        case '2022-07-21': {
          return '17º';
        }
        case '2022-10-25': {
          return '18º';
        }
        case '2022-11-09': {
          return '19º';
        }
        case '2022-12-29': {
          return '20º';
        }
        case '2023-01-26': {
          return '21º';
        }
        case '2023-03-01': {
          return '22º';
        }
        case '2023-03-20': {
          return '23°';
        }
        case '2023-03-23': {
          return '24°';
        }
        case '2023-06-28': {
          return '25°';
        }
        case '2023-09-25': {
          return '26°';
        }
        case '2023-09-28': {
          return '27°';
        }
        case '2023-10-19': {
          return '28°';
        }
        case '2023-10-23': {
          return '29°';
        }
        case '2024-01-24': {
          return '30°';
        }
        case '2024-03-15': {
          return '31°';
        }
        case '2024-04-16': {
          return '32°';
        }
        case '2024-06-04': {
          return '33°';
        }
        case '2024-06-24': {
          return '34°';
        }
        case '2024-10-01': {
          return '35°';
        }
        case '2024-10-16': {
          return '36°';
        }
        case '2025-01-27': {
          return '37°';
        }
        default: {
          return 'Não Informado';
        }
      }
    }

    function createCotacaoDolar(data: string): string {
      switch (data) {
        case '2018-10-27': {
          return '4,0273';
        }
        case '2019-04-08': {
          return '3,8129';
        }
        case '2019-06-30': {
          return '3,8234';
        }
        case '2019-11-04': {
          return '4,0041';
        }
        case '2020-02-03': {
          return '4,2013';
        }
        case '2020-06-25': {
          return '5,1705';
        }
        case '2020-09-02': {
          return '5,4679';
        }
        case '2020-10-23': {
          return '5,5675';
        }
        case '2021-02-03': {
          return '5,4759';
        }
        case '2021-05-11': {
          return '5,3872';
        }
        case '2021-05-24': {
          return '5,2822';
        }
        case '2021-06-24': {
          return '5,0314';
        }
        case '2021-09-30': {
          return '5,3435';
        }
        case '2022-02-04': {
          return '5,5213';
        }
        case '2022-05-26': {
          return '4,8777';
        }
        case '2022-06-22': {
          return '5,1313';
        }
        case '2022-07-21': {
          return '5,3904';
        }
        case '2022-10-25': {
          return '5,2808';
        }
        case '2022-11-09': {
          return '5,0360';
        }
        case '2022-12-29': {
          return '5,1445';
        }
        case '2023-01-26': {
          return '5,1986';
        }
        case '2023-03-01': {
          return '5,1791';
        }
        case '2023-03-20': {
          return '5,1353';
        }
        case '2023-03-23': {
          return '5,2679';
        }
        case '2023-06-28': {
          return '4,7799';
        }
        case '2023-09-25': {
          return '4,9131';
        }
        case '2023-09-28': {
          return '4,9606';
        }
        case '2023-10-19': {
          return '5,0862';
        }
        case '2023-10-23': {
          return '5,0568';
        }
        case '2024-01-24': {
          return '4,9223';
        }
        case '2024-03-15': {
          return '4,9751';
        }
        case '2024-04-16': {
          return '5,0520';
        }
        case '2024-06-04': {
          return '5,2416';
        }
        case '2024-06-24': {
          return '5,4254';
        }
        case '2024-10-01': {
          return '5,4431';
        }
        case '2024-10-16': {
          return '5,6045';
        }
        case '2025-01-27': {
          return '5,8925';
        }
        default: {
          return 'Não Informado';
        }
      }
    }

    return (
      <>
        {resultState === 'noItensFound' && <NoItensFound />}
        <WarehouseCostCards>
          {hits?.map(WareHouseFinanceiro => (
            <a key={WareHouseFinanceiro.serial_number + Date() + uudiv4()}>
              {isLoading ? (
                <Skeleton variant="circle">
                  <img alt="" />
                </Skeleton>
              ) : (
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/${WareHouseFinanceiro.part_number}.png`}
                  alt=""
                  onError={e => {
                    e.currentTarget.src = image_not_available;
                  }}
                />
              )}
              <div className="lote">
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    height="auto"
                    width="auto"
                    className="lote"
                  >
                    <strong>.</strong>
                  </Skeleton>
                ) : (
                  <strong>
                    {`${createNumberLote(
                      WareHouseFinanceiro.posting_date.slice(0, 10),
                    )}`}
                  </strong>
                )}

                {isLoading ? (
                  <Skeleton width="100%" height="auto" />
                ) : (
                  <p>Lote</p>
                )}
              </div>
              <div className="serial_number">
                {isLoading ? (
                  <Skeleton variant="text" width="100%" height="auto">
                    <strong>.</strong>
                  </Skeleton>
                ) : (
                  <strong>
                    {WareHouseFinanceiro.serial_number === ''
                      ? 'Falta informação no ERP'
                      : WareHouseFinanceiro.serial_number}
                  </strong>
                )}
                {isLoading ? (
                  <Skeleton variant="text" width="100%" height="auto">
                    <p>.</p>
                  </Skeleton>
                ) : (
                  <p>LPN / Nº Serial</p>
                )}
              </div>
              <div className="desc">
                {isLoading ? (
                  <Skeleton variant="text" width="100%" height="auto">
                    <strong>.</strong>
                  </Skeleton>
                ) : (
                  <strong>
                    {WareHouseFinanceiro.part_number === ''
                      ? 'Falta informação no ERP'
                      : WareHouseFinanceiro.part_number}
                  </strong>
                )}
                {isLoading ? (
                  <Skeleton variant="text" width="100%" height="auto">
                    <p>.</p>
                  </Skeleton>
                ) : (
                  <p>{WareHouseFinanceiro.description} </p>
                )}
              </div>

              <div className="total_cost">
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    height="auto"
                    width="auto"
                    className="total_cost"
                  >
                    <strong>.</strong>
                  </Skeleton>
                ) : (
                  <strong>
                    {`R$ ${WareHouseFinanceiro.total_cost.toLocaleString(
                      'pt',
                    )}`}
                  </strong>
                )}

                {isLoading ? (
                  <Skeleton width="100%" height="auto" />
                ) : (
                  <>
                    <p>Custo</p>
                    <p>
                      {`Cotação: $ ${createCotacaoDolar(
                        WareHouseFinanceiro.posting_date.slice(0, 10),
                      )}`}
                    </p>
                  </>
                )}
              </div>
            </a>
          ))}
        </WarehouseCostCards>
      </>
    );
  },
);

export default WarehouseListCost;
